<template>
  <div class="container-fluid">
    <div class="row">
      <h2 class="mt-3 mx-5">استعراض المنيو</h2>
      <div class="col-md-12 mx-2">
        <el-date-picker
          placeholder="اختر تاريخ"
          v-model="selectedDate"
          type="date"
          v-validate="'required'"
          data-vv-scope="addEditValidation"
          :data-vv-as="$t('selectedDate')"
          class="d-block my-2"
          name="selectedDate"
          hide-details
          dense
          @change="getMenu"
          outlined
          small
        >
        </el-date-picker>
      </div>
    </div>

    <div class="our-content" v-if="clist1.items.length > 0">
      <div class="row">
        <div class="col-md-6">
          <h3
            v-if="clist1.items.length > 0"
            class="mt-2 text-center"
            style="position: relative; top: 15px"
          >
            وجبات رئيسية
          </h3>
          <div v-if="clist1.items.length > 0" class="container">
            <div
              class="row mt-1"
              style="display: flex; justify-content: center"
            >
              <div
                class="col-md-4 mt-5"
                v-for="(item, index) in clist1.items"
                :key="index"
                style="
                  border: 1px solid #ccc;
                  text-align: center;
                  margin: 0 5px;
                  border-radius: 8px;
                "
              >
                <p
                  v-if="indexPlusSelected == null"
                  style="
                    font-size: 28px;
                    cursor: pointer;
                    color: blue;
                    position: relative;
                    top: -10px;
                    left: 85px;
                  "
                  @click="indexPlusSelected = index"
                >
                  +
                </p>
                <p
                  v-if="indexPlusSelected == index"
                  style="
                    font-size: 28px;
                    cursor: pointer;
                    color: red;
                    position: relative;
                    top: -10px;
                    left: 85px;
                  "
                  @click="indexPlusSelected = null"
                >
                  ×
                </p>
                <p
                  v-else-if="indexPlusSelected != null"
                  style="
                    font-size: 28px;
                    cursor: pointer;
                    color: red;
                    position: relative;
                    top: -10px;
                    left: 85px;
                    visibility: hidden;
                  "
                >
                  .
                </p>

                <template>
                  <div
                    v-if="item.exclude"
                    style="position: relaitve; right: 85%; bottom: 60px"
                    class="icon-container"
                  >
                    <div class="circle">
                      <div class="exclamation-mark">
                        <div class="line"></div>
                        <div class="dot"></div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-else
                    style="position: relaitve; right: 85%; bottom: 60px"
                    class="icon-container"
                  ></div>
                </template>

                <div class="item-content">
                  <img
                    :src="
                      item.image
                        ? item.image
                        : '/assets/default_customers/protein.png'
                    "
                    alt=""
                    width="100"
                    height="100"
                  />
                  <p class="text-center">
                    {{ item.recipie_protein_name }} |
                    {{ item.recipie_carb_name }}
                  </p>
                  <p class="text-center"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h3
            v-if="clist1.items.length > 0"
            class="text-center"
            style="margin-top: 25px"
          >
            فيشيب اختار لك &#128522;
          </h3>
          <div class="container">
            <div v-if="clist1.items.length > 0" class="container">
              <div
                class="row mt-1"
                style="display: flex; justify-content: center"
              >
                <div
                  class="col-md-4 mt-5"
                  v-for="(item2, index) in clist2.items"
                  :key="index"
                  style="
                    border: 1px solid #ccc;
                    text-align: center;
                    margin: 0 5px;
                    border-radius: 8px;
                  "
                >
                  <p
                    v-if="indexPlusSelected != null"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: green;
                      position: relative;
                      top: -5px;
                      left: 72px;
                    "
                    @click="replaceItem(index)"
                  >
                    ✓
                  </p>

                  <div class="item-content">
                    <img
                      style="margin-top: 5px"
                      :src="
                        item2.image
                          ? item2.image
                          : '/assets/default_customers/protein.png'
                      "
                      alt=""
                      width="100"
                      height="100"
                    />
                    <p class="text-center">
                      {{ item2.recipie_protein_name }} |
                      {{ item2.recipie_carb_name }}
                    </p>
                    <p class="text-center"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="col-md-6">
            <h3
              v-if="clist1.groups.length > 0"
              class="mt-2 text-center"
            >
              القوائم الرئيسية
            </h3>
             <hr
              v-if="clist1.groups.length > 0"
              style="display: block; margin-top: 18px"
            />
            <div v-if="clist1.groups.length > 0" class="container">
              <div
                class="row mt-1"
                style="display: flex; justify-content: center"
              >
                <div
                  class="col-md-4 mt-5"
                  v-for="(item, index) in clist1.groups"
                  :key="index"
                  style="
                    border: 1px solid #ccc;
                    text-align: center;
                    margin: 0 5px;
                    border-radius: 8px;
                  "
                >
                  <p
                    v-if="indexPlusSelected2 == null"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: blue;
                      position: relative;
                      top: -10px;
                      left: 85px;
                    "
                    @click="indexPlusSelected2 = index"
                  >
                    +
                  </p>
                  <p
                    v-if="indexPlusSelected2 == index"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: red;
                      position: relative;
                      top: -10px;
                      left: 85px;
                    "
                    @click="indexPlusSelected2 = null"
                  >
                    ×
                  </p>
                  <p
                    v-else-if="indexPlusSelected2 != null"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: red;
                      position: relative;
                      top: -10px;
                      left: 85px;
                      visibility: hidden;
                    "
                  >
                    .
                  </p>

                  <div class="item-content">
                    <img
                      :src="
                        item.image
                          ? item.image
                          : '/assets/default_customers/protein.png'
                      "
                      alt=""
                      width="100"
                      height="100"
                    />
                    <p class="text-center">
                      {{ item.group_name }} | {{ item.recipie_name }}
                    </p>
                    <p class="text-center"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            
            <h3
              v-if="clist1.groups.length > 0"
              class="text-center"
            >
              فيشيب اختار لك &#128522;
            </h3>

            <hr
              v-if="clist1.groups.length > 0"
              style="display: block; margin-top: 27px"
            />
            <div class="container">
              <div class="container">
            <div v-if="clist1.items.length > 0" class="container">
              <div
                class="row mt-1"
                style="display: flex; justify-content: center"
              >
                <div
                  class="col-md-4 mt-5"
                  v-for="(item2, index) in clist2.groups"
                  :key="index"
                  style="
                    border: 1px solid #ccc;
                    text-align: center;
                    margin: 0 5px;
                    border-radius: 8px;
                  "
                >
                  <p
                    v-if="indexPlusSelected2 != null"
                    style="
                      font-size: 28px;
                      cursor: pointer;
                      color: green;
                      position: relative;
                      top: -5px;
                      left: 72px;
                    "
                    @click="replaceItem2(index)"
                  >
                    ✓
                  </p>

                  <div class="item-content">
                    <img
                      style="margin-top: 5px"
                      :src="
                        item2.image
                          ? item2.image
                          : '/assets/default_customers/protein.png'
                      "
                      alt=""
                      width="100"
                      height="100"
                    />
                    <p class="text-center">
                      {{ item2.group_name }} |
                      {{ item2.recipie_name }}
                    </p>
                    <p class="text-center"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            
      <v-btn
        v-if="(clist2.items.length > 0 || clist2.groups.length > 0) && !pickerOptions()"
        @click="updateMenu()"
        class="mx-2 float-left"
        color="success"
        style="color: #fff"
      >
        تحديث
      </v-btn>
          </div>
        </div>

        
      </div>

    </div>

    <div v-else-if="isLoading && clist1.items.length == 0">

      <!-- <h1 style="text-align:center;color:red;">لا توجد وصفات متاحة</h1> -->

      <img style="display:block;margin:0 auto;" src="/assets/images/no_data2.png" width="700" height="300" alt="">
    </div>
  </div>
</template>

<script>
import moment from "moment";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },

  watch: {
    "$route.params": {
      handler: function (newParams, oldParams) {
        this.selectedDate= null;
      },
      immediate: true, // Call the handler immediately when the component is created
    },
  },
  
  data() {
    return {
      selectedDate: null,
      isLoading: false,
      indexPlusSelected: null,
      indexPlusSelected2: null,
      clist1: { items: [], groups: [] },
      clist2: { items: [], groups: [] },
    };
  },
  methods: {
  pickerOptions() {
    const time = moment(this.selectedDate);
    const currentDate = new Date();
    const sevenDaysBefore = new Date(currentDate);
    sevenDaysBefore.setDate(currentDate.getDate() + 1);
    const twoDaysAfter = new Date(currentDate);
    twoDaysAfter.setDate(currentDate.getDate() + 7);
    
    // Disable dates that are after 2 days ago or after 9 days from now
    return time < sevenDaysBefore || time > twoDaysAfter;
  },
    updateMenu() {
      let date = moment(this.selectedDate).format("YYYY-MM-DD");
      let params = {
        date: date,
        subscription_id: this.$route.params.id,
        items: this.clist2.items.map((x) => ({
          recipie_protein_id: x.recipie_protein_id + "",
          recipie_carb_id: x.recipie_carb_id + "",
        })),
        groups: this.clist2.groups.map((x) => ({
          recipie_id: x.recipie_id + "",
          group_id: x.group_id + "",
        })),
      };

      if (date) {
        this.$store
          .dispatch(`customers/updateMenu`, params)
          .then((res) => {
            this.getMenu();
            this.notifySuccess("تم تحديث الوجبات بنجاح", res);
          })
          .catch((error) => {
            // Handle errors here
          });
      }
    },

    getMenu() {
      let date = moment(this.selectedDate).format("YYYY-MM-DD");
      let params = {
        date: date,
        subscription_id: this.$route.params.id,
      };
      if (this.selectedDate) {
        this.$store
          .dispatch(`customers/getMenu`, params)
          .then((res) => {
            this.clist1 = res.data.right;
            this.clist2 = res.data.left;
          })
          .catch((error) => {
            this.isLoading= true;
            this.clist1.items = [];
            this.clist1.groups = [];
            this.clist2.items = [];
            this.clist2.groups = [];
          });
      } else {
         this.isLoading= false;
        this.clist1.items = [];
        this.clist1.groups = [];
        this.clist2.items = [];
        this.clist2.groups = [];
      }
    },
    replaceItem(index) {
      if (
        Array.isArray(this.clist1.items) &&
        Array.isArray(this.clist2.items) &&
        typeof this.indexPlusSelected != "undefined"
      ) {
        if (index >= 0 && index < this.clist2.items.length) {
          this.clist2.items[index] = this.clist1.items[this.indexPlusSelected];
          this.indexPlusSelected = null;
        } 
      }
    },
    replaceItem2(index) {
      if (
        Array.isArray(this.clist1.groups) &&
        Array.isArray(this.clist2.groups) &&
        typeof this.indexPlusSelected2 != "undefined"
      ) {
        if (index >= 0 && index < this.clist2.groups.length) {
          this.clist2.groups[index] =
            this.clist1.groups[this.indexPlusSelected2];
          this.indexPlusSelected2 = null;
        }
      } 
    },
  },
};
</script>

<style lang="scss">
.info-img {
  visibility: show !important;
}
@media (max-width: 970px) {
  img {
    display: block;
    margin: 0 auto;
  }
  .info-img {
    visibility: hidden !important;
  }

  /* Add your custom responsive styles here */
}

.item-card {
  border: 1px solid #ccc;
  text-align: center;
  margin: 0 5px;
  padding: 15px;
  border-radius: 10px;
}

.action-button {
  font-size: 28px;
  margin: 0 15px;
  cursor: pointer;
  color: blue;
}

.action-button-selected {
  color: red;
}

.item-content {
  width: 100%;
  img {
    width: 85%;
    padding: 0 5px;
    margin-top: -30px;
    border-radius: 8px;
  }
  p {
    font-size: 14px;
    font-weight: bold !important;
  }
}

.icon-container {
  position: relative;
  width: 30px;
  height: 30px;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid yellow;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exclamation-mark {
  position: relative;
}

.line {
  width: 3px;
  height: 10px;
  background-color: white;
  margin: 0 auto;
}

.dot {
  width: 3px;
  height: 3px;
  background-color: white;
  border-radius: 50%;
  margin: 0 auto;
}

.our-content {
  border: 1px solid #a0c9cb;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>
